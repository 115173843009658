import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { Text } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XL } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

type Props = {
    setAllInvestors: Function;
    allInvestors: boolean;
    returnOptions: string;
    setReturnOptions: Function;
};

export default function TopInvestorsHeader({ setAllInvestors, allInvestors, returnOptions, setReturnOptions }: Props) {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.container}>
            <View style={styles.buttonsDiv}>
                <View style={[styles.buttonContainer, styles.buttonPaddingRight]}>
                    <Pressable
                        style={[
                            styles.buttonStyle,
                            allInvestors ? styles.buttonBackgroundColorInactive : styles.buttonBackgroundColor,
                        ]}
                        onPress={() => setAllInvestors(false)}
                    >
                        <Text fontType="LATO_BOLD" style={styles.buttonTextStyle}>
                        followed-button
                        </Text>
                        {allInvestors ? (
                            <Entypo size={scaleSize(20)} name="eye-with-line" style={styles.eyeIcon} />
                        ) : null}
                    </Pressable>
                </View>
                <View style={styles.buttonContainer}>
                    <Pressable
                        onPress={() => setAllInvestors(true)}
                        style={[
                            styles.buttonStyle,
                            allInvestors ? styles.buttonBackgroundColor : styles.buttonBackgroundColorInactive,
                        ]}
                    >
                        <Text fontType="LATO_BOLD" style={styles.buttonTextStyle}>
                            all-investors
                        </Text>
                        {!allInvestors ? (
                            <Entypo size={scaleSize(20)} name="eye-with-line" style={styles.eyeIcon} />
                        ) : null}
                    </Pressable>
                </View>
            </View>
            <View style={styles.investorsListHeader}>
                <View style={styles.traderHeader}>
                    <Text fontType="LATO_BOLD" style={styles.headerText}>
                        trader
                    </Text>
                </View>
                <View style={styles.returnHeader}>
                    <Text fontType="LATO_BOLD" style={styles.headerText}>
                        return
                    </Text>
                    <Pressable
                        onPress={() => {
                            setReturnOptions(returnOptions === '1m' ? '12m' : returnOptions === '12m' ? '7d' : '1m');
                        }}
                    >
                        {returnOptions === '1m' ? (
                            <Text fontType="LATO_BOLD" style={styles.returnOptionsText}>
                                {' '}
                                1M
                            </Text>
                        ) : null}
                        {returnOptions === '12m' ? (
                            <Text fontType="LATO_BOLD" style={styles.returnOptionsText}>
                                {' '}
                                12M
                            </Text>
                        ) : null}
                        {returnOptions === '7d' ? (
                            <Text fontType="LATO_BOLD" style={styles.returnOptionsText}>
                                {' '}
                                7D
                            </Text>
                        ) : null}
                    </Pressable>
                    <View style={styles.dragIcon}>
                        <Entypo
                            name={'select-arrows'}
                            size={scaleSize(12)}
                            onPress={() => {
                                setReturnOptions(
                                    returnOptions === '1m' ? '12m' : returnOptions === '12m' ? '7d' : '1m'
                                );
                            }}
                            style={styles.dragButton}
                        />
                    </View>
                </View>
                <View style={styles.riskDiv}>
                    <Text fontType="LATO_BOLD" style={styles.headerText}>
                        avg-risk
                    </Text>
                </View>
                <View style={styles.followHeader}>
                    <Text fontType="LATO_BOLD" style={styles.headerText}>
                        {allInvestors ? 'follow-header' : 'forget-header'}
                    </Text>
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        riskDiv: { width: scaleSize(75) },
        followHeader: { paddingRight: scaleSize(5) },
        dragIcon: {
            alignItems: 'center',
            paddingLeft: scaleSize(1),
            justifyContent: 'center',
        },
        dragButton: {
            color: theme.switch_trackOn,
        },
        returnOptionsText: { fontSize: FONT_SIZE_S, color: theme.switch_trackOn },
        headerText: { fontSize: FONT_SIZE_S, color: theme.wallet_text },
        returnHeader: { flexDirection: 'row', width: scaleSize(95), justifyContent: 'flex-start' },
        traderHeader: { width: scaleSize(148), justifyContent: 'center', paddingLeft: scaleSize(5) },
        investorsListHeader: { flexDirection: 'row', width: '100%', justifyContent: 'flex-start' },
        buttonsDiv: {
            flexDirection: 'row',
            paddingTop: scaleSize(7),
            paddingBottom: scaleSize(25),
            justifyContent: 'flex-start',
        },
        eyeIcon: { color: theme.white, paddingLeft: scaleSize(5) },
        buttonPaddingRight: { paddingRight: scaleSize(7) },
        buttonTextStyle: { fontSize: FONT_SIZE_XL, color: theme.white },
        buttonBackgroundColor: { backgroundColor: theme.background_message },
        buttonBackgroundColorInactive: { backgroundColor: theme.switch_trackOff },
        buttonStyle: {
            flexDirection: 'row',
            borderRadius: scaleSize(5),
            height: scaleSize(40),
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        buttonContainer: { width: scaleSize(176.5), justifyContent: 'center', alignItems: 'center' },
        container: { width: '100%' },
        activeTabstyle: { color: theme.greenLineDesktop },
        inactiveTabstyle: { color: theme.equityValue },
        tabstyle: { marginHorizontal: 10, fontSize: FONT_SIZE_M },
    });
