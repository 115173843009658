import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform, Pressable } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useResult } from '~/hooks/useResult';
import ResultRow from './ResultRow';
import DateRow, { getDateRow } from './DateRow';
import FromToSelector from './FromToSelector';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import Loader from '~/components/core/Loader';
import { formatNumber } from '~/lib/format';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function Result() {
    const [fromButton, setFromButton] = useState(prevPeriod);
    const [toButton, setToButton] = useState(now);
    const { results, totalFormatted, totalDirection, loading } = useResult(fromButton, toButton);
    const styles = useStyles(stylesGenerator);
    const labels = ['all-results', 'ct-results', 'my-results'];
    const [labelIndex, setLabelIndex] = useState(0);
    const [cresult, setCresult] = useState(totalFormatted);
    const [cdirection, setCdirection] = useState(totalDirection);
    const [openResult, setOpenResult] = useState('');

    useEffect(() => {
        if (!loading) {
            calculateResult(labelIndex);
        }
    }, [results, totalFormatted, totalDirection, loading]);

    function changeLabelIndex() {
        calculateResult(labelIndex + 1);
        if (labelIndex < 2) {
            setLabelIndex(labelIndex + 1);
        }
        else {
            setLabelIndex(0);
        }
    }

    function calculateResult(resultIndex: number) {
        let ctresult = 0;
        for (let i = 0; i < results.length; i++) {
            if (resultIndex === 1) {
                if (results[i].investorName) {
                    ctresult = ctresult + results[i].result;
                }
            }
            else if (resultIndex === 2) {
                if (!results[i].investorName) {
                    ctresult = ctresult + results[i].result;
                }
            } else {
                ctresult = ctresult + results[i].result;
            }
        }
        if (ctresult < 0) {
            setCdirection('negative')
        }
        else {
            setCdirection('positive')
        }
        setCresult(formatNumber(ctresult, 2));
    }

    return (
        <>
            <Loader loading={loading} />
            <ScrollbarWrapper>
                <View style={styles.ordersContainer}>
                    {results.length !== 0 &&
                        results.map((result, key) => (
                            <View style={styles.container} key={key}>
                                {getDateRow(result.timestamp, key) !== '' ? (
                                    <DateRow timestamp={result.timestamp} />
                                ) : null}
                                <Pressable
                                    onPress={() => result.investorName ? setOpenResult(openResult === result.id ? '' : result.id) : null}
                                    style={styles.button}
                                    key={key}
                                >
                                    <ResultRow result={result}  isOpen={openResult === result.id} />
                                </Pressable>
                            </View>
                        ))}
                </View>
            </ScrollbarWrapper>
            <FromToSelector
                setFromButton={setFromButton}
                setToButton={setToButton}
                prevPeriod={prevPeriod}
                totalFormatted={cresult ? cresult : totalFormatted}
                totalDirection={cdirection ? cdirection : totalDirection}
                resultFunction={changeLabelIndex}
                resultLabel={labels[labelIndex]}
            />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        button: { width: '100%' },
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        container: { width: '100%' },
    });
