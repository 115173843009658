import { useEffect, useState } from 'react';
import CopyTradingService from '~/api-services/CopyTradingService';
import { Investor } from '~/api-services/CopyTradingService/types';
import i18n from '~/i18n';

export const useInvestors = (isScreenActive: boolean) => {
    const [investors, setInvestors] = useState<Investor[]>(null);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [loading, setloading] = useState(true);

    async function addFollower(investorUid: string, investorName: string): Promise<string> {
        setloading(true);
        const { status, data } = await CopyTradingService.addFollower(investorUid);
        if (status === 200 && data.status) {
            getTopInvestitors();
            return '';
        }
        if (status === 200 && !data.status) {
            if (data.errorCode === 'CopyTradingNotEnoughMoney') {
                var messageContext: any = {};
                messageContext.investorName = investorName;
                var message = i18n.t('copy-trading-not-enough-money', messageContext);
                setConfirmationProps({ text: message, error: true });
                setConfirmationVisible(true);
            } else if (data.errorCode === 'CopyTradingMaxFollowing') {
                var messageContext: any = {};
                messageContext.maxFollow = data.errorContext.maxFollow;
                var message = i18n.t('max-follow', messageContext);
                setConfirmationProps({ text: message, error: true });
                setConfirmationVisible(true);
            } else {
                if (data.errorCode !== 'CopyTradingNotConfirmed') {
                    setConfirmationProps({ text: 'error-' + data.errorCode, error: true });
                    setConfirmationVisible(true);
                }
            }
            setloading(false);
            return data.errorCode;
        }
    }

    async function removeFollower(investorUid: string) {
        setloading(true);
        const { status, data } = await CopyTradingService.removeFollower(investorUid);
        if (status === 200 && data.status) {
            getTopInvestitors();
        }
        if (status === 200 && !data.status) {
            setConfirmationProps({ text: 'error-' + data.errorCode, error: true });
            setConfirmationVisible(true);
        }
        setloading(false);
    }

    async function getTopInvestitors() {
        setloading(true);
        const { status, data } = await CopyTradingService.getTopInvestitors();
        if (status === 200 && data.status) {
            setInvestors(data.returnData);
        }
        setloading(false);
    }

    useEffect(() => {
        if (isScreenActive) {
            getTopInvestitors();
        }
    }, [isScreenActive]);

    return {
        investors,
        addFollower,
        removeFollower,
        confirmationVisible,
        setConfirmationVisible,
        confirmationProps,
        setConfirmationProps,
        loading,
    };
};
