import config from '~/config';
import axios from '../baseClient';
import { TopInvestorResponse, FavoriteResponse, FollowerResponse, GetResponse } from './types';

class CopyTradingService {
    private static domain = '/api/socialtrading';

    getTopInvestitors() {
        return axios.post<TopInvestorResponse>(`${CopyTradingService.domain}/investor/top`, {
            website: config.WEBSITE,
        });
    }

    addFavourite(investorUid: string) {
        return axios.post<FavoriteResponse>(`${CopyTradingService.domain}/investor/addFavourite`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    removeFavourite(investorUid: string) {
        return axios.post<FavoriteResponse>(`${CopyTradingService.domain}/investor/removeFavourite`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    addFollower(investorUid: string) {
        return axios.post<FollowerResponse>(`${CopyTradingService.domain}/investor/addFollower`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    removeFollower(investorUid: string) {
        return axios.post<FollowerResponse>(`${CopyTradingService.domain}/investor/removeFollower`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    get(investorUid: string) {
        return axios.post<GetResponse>(`${CopyTradingService.domain}/investor/get`, {
            investorUid: investorUid,
            website: config.WEBSITE,
        });
    }

    confirm() {
        return axios.post<GetResponse>(`${CopyTradingService.domain}/account/confirm`, { website: config.WEBSITE });
    }
}

export default new CopyTradingService();
