import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector } from '~/state/selectors';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { TraderAccountReturnData } from '~/api-services/AuthDataService/types';
import { AntDesign } from '@expo/vector-icons';
import config from '~/config';

type Props = {
    traderAccount: TraderAccountReturnData;
    selectedAccountId: number;
};

export default function TraderAccountRow({ traderAccount, selectedAccountId }: Props) {
    console.log(traderAccount);
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    return (
        <>
            <View
                style={[
                    styles.positionGroupContainer,
                    styles.positionGroupClose,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
                key={'traderAccountContainer' + traderAccount.accountId}
            >
                <View style={styles.columnContainer}>
                    <Text>{'account-id'}</Text>
                    <Text>{traderAccount.accountId.toString()}</Text>
                </View>
                <View style={styles.columnContainer}>
                    <Text>{'currency'}</Text>
                    <Text>{traderAccount.currency}</Text>
                </View>
                <View style={styles.columnContainer}>
                    <Text>{'account-balance-title'}</Text>
                    <Text>{traderAccount.balance.toString()}</Text>
                </View>
                <View style={styles.columnContainer}>
                    {selectedAccountId === traderAccount.accountId ?
                        <AntDesign name="checkcircle" size={scaleSize(18)} style={styles.iconColor} />
                        : null}
                </View>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        iconColor: { color: theme.tabIconSelected },
        columnContainer: { flexWrap: 'wrap', marginHorizontal: 'auto', paddingLeft: '1%', justifyContent: 'center' },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
        },
        positionGroupClose: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },


    });
